import areasStore from '../../views/pages/admin/organization/areas/store/index'
import auth from './auth/'
import bundlesStore from '../../views/pages/social/tools/bundle/store/index'
import calendar from '../../views/pages/social/calendar/store/reducer'
import chat from '@src/views/pages/social/chat/store/reducer'
import chatReducer from './chat/'
import { combineReducers } from 'redux'
import competition from '@src/views/pages/social/Visibility/store/reducer'
import crawlReducer from '../../views/pages/social/crawled/store/reducer'
import customizer from './customizer/'
import editUserReducer from '../../views/pages/social/editlight/store/reducer'
import gcalendar from '../../views/pages/social/giveaway/store/reducer.js'
import gifts from '@src/views/pages/social/gifts/create/store/reducer'
import layout from './layout'
import manageGifts from '@src/views/pages/social/gifts/manage/store/reducer'
import manualCreatorReducer from '../../views/pages/social/CreateInfluencer/manual/store/reducer'
import navbar from './navbar'
import notificationReducer from './notification'
import postModalReducer from './postModal'
import signalReducer from './signal'
import userGrid from './userGrid'
import users from '@src/views/pages/admin/organization/users/store'
import view from '@src/views/pages/social/view/store/index'

const rootReducer = combineReducers({
  view,
  users,
  chat,
  layout,
  customizer,
  auth,
  chatApp: chatReducer,
  navbar,
  calendar,
  userGrid,
  postModal: postModalReducer,
  signal: signalReducer,
  notification: notificationReducer,
  gifts,
  manageGifts,
  competition,
  crawlReducer,
  manualCreator: manualCreatorReducer,
  gcalendar,
  editUserReducer,
  areasStore,
  bundlesStore
})

export default rootReducer
