/* eslint-disable no-use-before-define */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import BundlesService from '../../../../../../services/api/bundles'

export const switchModal = createAsyncThunk(
  'bundlesStore/switchModal',
  async (params, { getState }) => {
    return true
  }
)

export const getBundles = createAsyncThunk(
  'bundlesStore/getBundles',
  async (params, { getState }) => {
    const request = await BundlesService.get()

    return request.data
  }
)

export const delBundle = createAsyncThunk(
  'bundlesStore/delBundle',
  async (params, { getState }) => {
    await BundlesService.del(params)
    const bundles = await BundlesService.get()

    return bundles
  }
)

export const saveBundle = createAsyncThunk(
  'bundlesStore/saveBundle',
  async (params, { getState }) => {
    const request = await BundlesService.post(params)
    const bundles = await BundlesService.get()

    return { request, bundles }
  }
)

export const selectBundle = (bundle) => (dispatch) => {
  dispatch(setSelectedBundle(bundle))
  dispatch(switchModal(true)) // Open the modal
}

export const bundlesStore = createSlice({
  name: 'bundlesStore',
  initialState: {
    createModal: false,
    count: 0,
    selectedBundle: null, // Store the selected bundle

    bundles: [],
    loading: false
  },
  reducers: {
    setSelectedBundle: (state, action) => {
      const bundle = state.bundles.filter((b) => b.id === action.payload) // Set the selected bundle

      state.selectedBundle = bundle[0]
    },
    clearSelectedBundle: (state) => {
      state.selectedBundle = null // Clear selected bundle when modal closes
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(switchModal.fulfilled, (state) => {
        state.createModal = !state.createModal
      })
      .addCase(getBundles.pending, (state) => {
        state.loading = true
      })
      .addCase(getBundles.rejected, (state) => {
        state.loading = false
      })
      .addCase(getBundles.fulfilled, (state, action) => {
        state.bundles = action.payload
        state.loading = false
      })
      .addCase(delBundle.pending, (state) => {
        state.loading = true
      })
      .addCase(delBundle.rejected, (state) => {
        state.loading = false
      })
      .addCase(delBundle.fulfilled, (state, action) => {
        state.bundles = action.payload.data
        state.loading = false
      })

      .addCase(saveBundle.fulfilled, (state, action) => {
        state.bundles = action.payload.bundles.data
        state.loading = false
      })
    // .addCase(loadCountries.rejected, (state, action) => {})
    // .addCase(loadCountries.fulfilled, (state, action) => {
    //   state.countries = action.payload
    //   state.loadingCountries = false
    // })
  }
})

export const { setSelectedBundle, clearSelectedBundle } = bundlesStore.actions

export default bundlesStore.reducer
